import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/Logo.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";


import githubicon from "../assets/img/github.svg";
import upworkicon from "../assets/img/upwork.svg";



export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center pt-5">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6} className="text-center text-sm-start" >
          <Col size={12} sm={4} className="text-center text-sm-start" >
            <img  src={logo} alt="Logo" />
            </Col>
            <p>Email: kunalagrawala.k@gmail.com <br /> Phone: +91 96647 66487</p> 
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a   target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/kunal-agrawal-157084210/"><img src={navIcon1} alt="Icon" /></a>
              <a  target="_blank" rel="noopener noreferrer" href="https://www.upwork.com/freelancers/~0156690ad5ae6f6aa7"><img src={upworkicon} alt="Icon" /></a>
              <a  target="_blank" rel="noopener noreferrer" href="https://github.com/ak-kunal"><img src={githubicon} alt="Icon" /></a>
            </div>
            <p>Copyright 2022. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
